import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import moment from "moment";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class AdminService {
	constructor(private http: HttpClient, private authSvc: AuthService, private projectSvc: ProjectService, private helpersSvc: HelpersService) {}

	getPersons(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/persons?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPerson(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/person/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getEvaluationsPeriod(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/evaluations-period?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getEvaluationPeriods(userId: number, filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/evaluators/" + userId + "?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllDepartaments(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params };

			const url = environment.urlDomain + "/api/v1/admin/medals-departamentos?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	getMedals(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			if (filtros.startDate) {
				filtros = {
					...filtros,
					fechaStart: filtros.startDate,
					fechaEnd: filtros.endDate,
				};
			}

			if (filtros.competencia && filtros.compentencia != "" && filtros.competencia.id) {
				filtros = {
					...filtros,
					competencia: filtros.competencia.id,
				};
			}

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/medals?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCompetencies(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/competencies?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getMedal(id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/medals/" + id;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCompetency(id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/competencies/" + id;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCompetencyRestriccions(id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/competencyRestriccions/" + id;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	recibirRestriccionPersona(id, form): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = form;

			const url = environment.urlDomain + "/api/v1/admin/crearCompetencyReceivedPerson/" + id;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	otorgarRestriccionPersona(id, form): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = form;

			const url = environment.urlDomain + "/api/v1/admin/crearCompetencyAvailablePerson/" + id;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	recibirRestriccionPuesto(id, form): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = form;

			const url = environment.urlDomain + "/api/v1/admin/crearCompetencyReceivedPuesto/" + id;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	moderateMedal(id, status): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/moderateMedal/" + id;
			this.http.put(url, { status }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	otorgarRestriccionPuesto(id, form): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = form;

			const url = environment.urlDomain + "/api/v1/admin/crearCompetencyAvailablePuesto/" + id;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	deletes(mode, id): Observable<any> {
		//Segun el mode que pasemos se pasara una url u otra para ver de que tabla se borra el dato

		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			let dicc_modos = {
				otPersona: "deleteCompetencyReceivedPerson",
				otPuesto: "deleteCompetencyReceivedPuesto",
				avPersona: "deleteCompetencyAvailablePerson",
				avPuesto: "deleteCompetencyAvailablePuesto",
			};
			let enlace = dicc_modos[mode];
			console.log("url para el delete ", enlace);

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = {
				id: id,
			};
			const url = environment.urlDomain + `/api/v1/admin/${enlace}?` + this.helpersSvc.serializeData(parametros);

			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	changeEnabledIndicator(id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/indicator-activo-change/" + id;

			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createIndicator(id, form): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = form;

			const url = environment.urlDomain + "/api/v1/admin/indicator-create/" + id;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPrivateFeedbacks(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProjectId(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/private-feedbacks?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPeriods(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params };

			const url = environment.urlDomain + "/api/v1/admin/periods?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getEvaluationPeriod(evaluationId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/evaluation-period/" + evaluationId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasProyecto(nombre = null): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = {
				id: this.projectSvc.getProyectoAdmin(),
				name: nombre,
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-proyecto?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearDiasVacaciones(personas: any[]): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const body = personas;

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
					"Access-Control-Allow-Origin": "*",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/dias-vacaciones";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					console.log("Días de vacaciones creados: ", response);
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasProyectoSinDiasVacaciones(nombre = null, agno = new Date().getFullYear()): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = {
				id: this.projectSvc.getProyectoAdmin(),
				name: nombre,
				agno: agno,
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-proyecto-sin-dias-vacaciones?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasMedallas(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/persons-medals?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPuestos(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/puestos?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCompetenciasMedallas(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/competencies-medals?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDelegacionesInformes(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/delegaciones-informes?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getTiendaInformes(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/tienda-informes?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCategoriasCompetencias(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/competencies-categories?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getIndicadoresCompetenciaID(competencyId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/indicadores-competencia/" + competencyId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasPorPuesto(puestoId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-por-puesto/" + puestoId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasPorDepartamento(departamentId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-departamento/" + departamentId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasPorCompanie(companieId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-companie/" + companieId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasRestriccion(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: personId };

			const url = environment.urlDomain + "/api/v1/admin/personas-restriccion?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	addPersonasRestriccion(personId, restrictionId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = { personId: restrictionId };

			const url = environment.urlDomain + "/api/v1/admin/personas-restriccion/" + personId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deletePersonasRestriccion(personId, restrictionId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { personId: restrictionId };

			const url = environment.urlDomain + "/api/v1/admin/personas-restriccion/" + personId + "?" + this.helpersSvc.serializeData(parametros);
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getClimasInformes(filtros): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			if (filtros?.startDate) {
				filtros = {
					fechaStart: moment(filtros.startDate).format("YYYY-MM-DD HH:mm:ss"),
					fechaEnd: moment(filtros.endDate).format("YYYY-MM-DD HH:mm:ss"),
				};
			}

			const parametros = { ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/climas-informes?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getRanking(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { proyecto: this.projectSvc.getProyectoAdmin() };

			const url = environment.urlDomain + "/api/v1/admin/ranking-informes?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPublicacionesInformes(filtros): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			if (filtros.startDate) {
				filtros = {
					fechaStart: filtros.startDate,
					fechaEnd: filtros.endDate,
					proyecto: this.projectSvc.getProyectoAdmin(),
				};
			}

			console.log(filtros);

			const parametros = {
				...filtros,
			};

			const url = environment.urlDomain + "/api/v1/admin/publicaciones-informes?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getMedalsInformes(filtros): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			if (filtros.startDate) {
				filtros = {
					...filtros,
					fechaStart: filtros.startDate,
					fechaEnd: filtros.endDate,
					proyecto: this.projectSvc.getProyectoAdmin(),
				};
			}

			if (filtros.competencia && filtros.compentencia != "" && filtros.competencia.id) {
				filtros = {
					...filtros,
					competencia: filtros.competencia.id,
				};
			}

			console.log(filtros);

			const parametros = {
				...filtros,
			};

			const url = environment.urlDomain + "/api/v1/admin/medals-informes?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasEvaluar(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: personId };

			const url = environment.urlDomain + "/api/v1/admin/personas-evaluar?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	addPersonasEvaluar(evaluatorId, persons): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = { persons: JSON.stringify(persons) };

			const url = environment.urlDomain + "/api/v1/admin/personas-evaluar/" + evaluatorId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deletePersonasEvaluar(personId, evaluatorId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { evaluatorId: evaluatorId };

			const url = environment.urlDomain + "/api/v1/admin/personas-evaluar/" + personId + "?" + this.helpersSvc.serializeData(parametros);
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasControlGastos(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: personId };

			const url = environment.urlDomain + "/api/v1/admin/personas-control-gastos?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	addPersonasControlGastos(personId, persons): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = { persons: JSON.stringify(persons) };

			const url = environment.urlDomain + "/api/v1/admin/personas-control-gastos/" + personId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deletePersonasControlGastos(personId, evaluarId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { controlId: evaluarId };

			const url = environment.urlDomain + "/api/v1/admin/personas-control-gastos/" + personId + "?" + this.helpersSvc.serializeData(parametros);
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonasEquipo(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { id: personId };

			const url = environment.urlDomain + "/api/v1/admin/personas-equipo?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	addPersonasEquipo(personId, persons): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = { persons: JSON.stringify(persons) };

			const url = environment.urlDomain + "/api/v1/admin/personas-equipo/" + personId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deletePersonasEquipo(personId, evaluarId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const parametros = { equipoId: evaluarId };

			const url = environment.urlDomain + "/api/v1/admin/personas-equipo/" + personId + "?" + this.helpersSvc.serializeData(parametros);
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getRoles(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/roles";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getTiposJornada(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/tipos-jornada";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCompanies(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/organigrama/companies?id=" + this.projectSvc.getProyectoAdmin();
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDepartmentsByParent(parentId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/organigrama/" + parentId + "/departments";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPuestosDepartamento(parentId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/organigrama/puestos/" + parentId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getGruposComparacion(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/grupos-comparacion?id=" + this.projectSvc.getProyectoAdmin();
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPermisosDocumentos(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/permisos-documentos";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonsByPeriod(personId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/evaluators-period?personId=" + personId + "&periodId=" + periodId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	editarPersona(formulario, personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				general: formulario,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/update-person/" + personId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearPersona(formulario): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				general: formulario,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/person";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	editarPeriodoEvaluacion(formulario, evaluationId, notificaciones, notificacionesEliminar): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				datos: formulario,
				proyecto: this.projectSvc.getProyectoAdmin(),
				notificaciones: JSON.stringify(notificaciones),
				idsNotificacionesEliminar: notificacionesEliminar,
			};

			const url = environment.urlDomain + "/api/v1/admin/evaluation-period/" + evaluationId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	editarMedalla(formulario, medallaId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				datos: formulario,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/edit-medal/" + medallaId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearPeriodoEvaluacion(formulario, notificaciones): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				datos: formulario,
				proyecto: this.projectSvc.getProyectoAdmin(),
				notificaciones: JSON.stringify(notificaciones),
			};

			const url = environment.urlDomain + "/api/v1/admin/evaluation-period";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	cuentaMediaPeriodoChange(cuenta, evaluationId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				cuenta: cuenta,
			};

			const url = environment.urlDomain + "/api/v1/admin/evaluation-period-cuenta-media/" + evaluationId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	feedbackPrivadoActivoChange(activo, feedbackId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				activo: activo,
			};

			const url = environment.urlDomain + "/api/v1/admin/private-feedbacks-activo-change/" + feedbackId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	medalsActivoChange(activo, medalId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				activo: activo,
			};

			const url = environment.urlDomain + "/api/v1/admin/medals-activo-change/" + medalId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	competencyActivoChange(activo, competencyId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				activo: activo,
			};

			const url = environment.urlDomain + "/api/v1/admin/competency-activo-change/" + competencyId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	competenciasActivoChange(activo, medalId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				activo: activo,
			};

			const url = environment.urlDomain + "/api/v1/admin/competencias-activo-change/" + medalId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updateCompetency(valores, medalId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				valores: valores,
			};

			const url = environment.urlDomain + "/api/v1/admin/update-competency/" + medalId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updateIndicador(valores, indicadorId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				valores: valores,
			};

			const url = environment.urlDomain + "/api/v1/admin/update-indicador/" + indicadorId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearCompetency(valores): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				valores: valores,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/crear-competency";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updateMedal(valores, medalId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				valores: valores,
				id: medalId,
			};

			const url = environment.urlDomain + "/api/v1/admin/update-medal/" + medalId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	editarDatosBancarios(formulario, personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				datos: formulario,
			};

			const url = environment.urlDomain + "/api/v1/admin/datos-bancarios/" + personId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	historicoPuestosPersona(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/historico-puestos/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	historicoPersonasEvaluar(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/historico-personas-evaluar/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	personasPuesto(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-puestos/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	personaDelegacion(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/persona-delegacion/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	personasGroups(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-grupos/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarHistoricoPuesto(formulario, historicoId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/historico-puesto/" + historicoId;
			this.http.put(url, formulario, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarHistoricoPersonasEvaluar(formulario, historicoId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/historico-personas/" + historicoId;
			this.http.put(url, formulario, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPlans(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };
			const url = `${environment.urlDomain}/api/v1/admin/action-plan?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getPlansObjetivos(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };
			const url = `${environment.urlDomain}/api/v1/admin/action-plan-objetivos?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getAllPeriodosRevision(isHistorico: boolean): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/action-plan-revisiones${isHistorico ? "?ishistorico=1" : ""}`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getPlansObjetivosResumen(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };
			const url = `${environment.urlDomain}/api/v1/admin/action-plan-objetivos-resumen?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updatePlan(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/action-plan/${id}`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSolicitudesVacaciones(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/solicitudes-vacaciones`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateSolicitudVacaciones(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/solicitudes-vacaciones/${id}`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDiasVacaciones(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/dias-vacaciones`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getDiasVacacionesAgrupados(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/dias-vacaciones-agrupados`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateDiaVacaciones(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/dias-vacaciones/${id}`;
			const body = { ...data, projectId: this.projectSvc.getProyectoAdmin() };

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updateDiasVacaciones(data) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/dias-vacaciones`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	postDiasVacaciones(data) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/dias-vacaciones`;
			const body = data;

			this.http.post(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	postDiasVacacionesIndividual(data) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/dias-vacaciones-individual`;
			const body = data;

			this.http.post(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getIncidencias(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/incidencias`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateIncidencia(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/incidencias/${id}`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getUnidadesFuncionales(filtros: any, numPage: number, pageSize: number, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/unidades-funcionales`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateUnidadFuncional(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/unidades-funcionales/${id}`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	createUnidadFuncional(data) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/unidades-funcionales`;
			const body = { ...data, id_proyecto: this.projectSvc.getProyectoAdmin() };

			this.http.post(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteUnidadFuncional(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/unidades-funcionales/${id}`;

			this.http.delete(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getRelacionesJerarquicas(filtros: any, numPage: number, pageSize: number = environment.pageSize, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/relaciones-jerarquicas`;

			this.http.get(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateRelacionJerarquica(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/relaciones-jerarquicas/${id}`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	createRelacionJerarquica(data) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/relaciones-jerarquicas`;
			const body = {
				...data,
				id_proyecto: this.projectSvc.getProyectoAdmin(),
			};

			this.http.post(url, body, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteRelacionJerarquica(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/relaciones-jerarquicas/${id}`;

			this.http.delete(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	exportarRelacionesJerarquicas(filtros: any, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				proyecto: this.projectSvc.getProyectoAdmin(),
				orden: JSON.stringify(orden),
			};

			const parametros = { ...body, ...filtros };

			const url = `${environment.urlDomain}/api/v1/admin/new-task/exportacion_relaciones_jerarquicas`;

			this.http.post(url, parametros, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	exportarUsuarios(filtros: any, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				proyecto: this.projectSvc.getProyectoAdmin(),
				orden: JSON.stringify(orden),
			};

			const parametros = { ...body, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/new-task/exportacion_usuarios";
			this.http.post(url, parametros, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	exportarPeriodosEvaluacion(filtros: any, formato: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				proyecto: this.projectSvc.getProyectoAdmin(),
				formato,
			};

			const parametros = { ...body, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/new-task/exportacion_periodos_evaluacion";
			this.http.post(url, parametros, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	exportarPlanesDeAccion(filtros: any, formato: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				proyecto: this.projectSvc.getProyectoAdmin(),
				formato: formato,
			};

			const parametros = { ...body, ...filtros };

			const url = `${environment.urlDomain}/api/v1/admin/new-task/exportacion_planes_de_accion`;

			this.http.post(url, parametros, options).subscribe(
				(res: any) => {
					observer.next(res);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	exportarSolicitudesVacaciones(filtros: any, formato: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				formato: formato,
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/new-task/exportacion_solicitudes_vacaciones`;

			this.http.get(url, options).subscribe(
				(res: any) => {
					observer.next(res);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	exportarDiasVacaciones(filtros: any, formato: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				formato: formato,
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/new-task/exportacion_dias_vacaciones`;

			this.http.get(url, options).subscribe(
				(res: any) => {
					observer.next(res);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	exportarIncidencias(filtros: any, formato: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				formato: formato,
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/new-task/exportacion_incidencias`;

			this.http.get(url, options).subscribe(
				(res: any) => {
					observer.next(res);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	exportarUnidadesFuncionales(filtros: any, formato: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				formato: formato,
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: parametros,
			};

			const url = `${environment.urlDomain}/api/v1/admin/new-task/exportacion_unidades_funcionales`;

			this.http.get(url, options).subscribe(
				(res: any) => {
					observer.next(res);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getVacacionesSummary(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/personas-vacaciones/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getRegistroJornadaUsuario(personId: number, limit: number, page: number, filtros: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.tipo != null && filtros.tipo !== "") {
				options.params = options.params.set("tipo", filtros.tipo);
			}
			if (filtros.entrada != null && filtros.entrada !== "") {
				options.params = options.params.set("entrada", filtros.entrada);
			}

			if (filtros.end != null && filtros.end !== "") {
				options.params = options.params.set("end", filtros.end);
			}
			if (filtros.start != null && filtros.start !== "") {
				options.params = options.params.set("start", filtros.start);
			}

			const url = environment.urlDomain + "/api/v1/admin/personas-jornada/" + personId + "/" + limit + "/" + page;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	getGraphJornadaUsuario(personId: number, filtros: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.tipo != null && filtros.tipo !== "") options.params = options.params.set("tipo", filtros.tipo);
			if (filtros.entrada != null && filtros.entrada !== "") options.params = options.params.set("entrada", filtros.entrada);
			if (filtros.end != null && filtros.end !== "") options.params = options.params.set("end", filtros.end);
			if (filtros.start != null && filtros.start !== "") options.params = options.params.set("start", filtros.start);

			const url = environment.urlDomain + "/api/v1/admin/personas-jornada-graph/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getEntryTypes(projectId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/getEntryTypes/" + projectId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDataBajasUsuario(personId: number, limit: number, page: number, filtros: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.tipo != null && filtros.tipo !== "") options.params = options.params.set("tipo", filtros.tipo);
			if (filtros.fecha_min != null && filtros.fecha_min !== "") {
				options.params = options.params.set("fecha_min", filtros.fecha_min);
			}
			if (filtros.fecha_max != null && filtros.fecha_max !== "") options.params = options.params.set("fecha_max", filtros.fecha_max);

			const url = environment.urlDomain + "/api/v1/admin/getDataBajasUsuario/" + personId + "/" + limit + "/" + page;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	getGraphsBajasUsuario(personId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/getGraphsBajasUsuario/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getOneDocumentIncidencia(incidenciaId: number): Observable<Blob> {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
			responseType: "blob" as "json", // Convertir a 'json' para la asignación de tipos
		};

		const url = `${environment.urlDomain}/api/v1/admin/getDocumentIncidencia/${incidenciaId}`;

		return this.http.get(url, options).pipe(map((response: any) => new Blob([response], { type: "application/octet-stream" })));
	}

	getCesions(filtros: any, numPage: number, pageSize: number = environment.pageSize): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/cesiones?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCesion(id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const params = {
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params };

			const url = environment.urlDomain + "/api/v1/admin/cesiones/" + id + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	startCesion(filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const params = {
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/new-cesion?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearCesion(formulario: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				general: formulario,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/cesion";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	updateCesion(data, id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/cesiones/${id}`;
			const body = data;

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDesgloseHorario(filtros: any, numPage: number, pageSize: number = environment.pageSize): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/control-horario/desglose?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getTotalDesgloseHorario(filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = { proyecto: this.projectSvc.getProyectoAdmin() };
			const parametros = { ...params, ...filtros };
			const url = environment.urlDomain + "/api/v1/admin/control-horario-total?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log(err);
					AlertError.showError(err);
				}
			);
		});
	}

	getProductos(filtros: any, numPage: number, pageSize: number = environment.pageSize): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			if (filtros.fecha_creacion?.startDate && filtros.fecha_creacion?.endDate) {
				filtros.fecha_creacion_startDate = moment(filtros.fecha_creacion.startDate).format("YYYY-MM-DD");
				filtros.fecha_creacion_endDate = moment(filtros.fecha_creacion.endDate).format("YYYY-MM-DD");
			}

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/admin/shop?" + this.helpersSvc.serializeData(parametros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearProducto(producto: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				valores: producto,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/product";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updateProducto(id, producto: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/producto/${id}`;
			const body = {
				valores: producto,
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	updateProductoDelegaciones(id, delegaciones: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/productoDelegacion/${id}`;
			const body = {
				delegaciones,
			};

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updateProductoPuestos(id, puestos: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/productoPuestos/${id}`;
			const body = {
				puestos,
			};

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	enableProducto(id, estado: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = `${environment.urlDomain}/api/v1/admin/enableProducto/${id}/${estado}`;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	deleteProducto(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/producto/${id}`;

			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSeguimientoByPeriod(periodId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/seguimiento/resumen/" + periodId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSeguimientoFromAgrupaciones(periodId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/getSeguimientoPorGrupo/" + periodId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSeguimientoFromManager(periodId: number, delegacionId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/getSeguimientoPorManagers/" + periodId + "/" + delegacionId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSeguimientoFromDelegaciones(periodId: number, agrupacionId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/getSeguimientoPorDelegacion/" + periodId + "/" + agrupacionId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllPublicacionesAdmin(projectId: number, limmit: number, page: number, filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.fecha_min != null && filtros.fecha_min !== "")
				options.params = options.params.set("fecha_min", moment(filtros.fecha_min).format("YYYY-MM-DD") + " 00:00:00");
			if (filtros.fecha_max != null && filtros.fecha_max !== "")
				options.params = options.params.set("fecha_max", moment(filtros.fecha_max).format("YYYY-MM-DD") + " 23:59:59");
			if (filtros.enabled != null && filtros.enabled !== "") options.params = options.params.set("enabled", filtros.enabled);
			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);
			if (filtros.tipo != null && filtros.tipo !== "") options.params = options.params.set("tipo", filtros.tipo);
			if (filtros.autor != null && filtros.autor !== "") options.params = options.params.set("autor", filtros.autor);

			const url = `${environment.urlDomain}/api/v1/admin/getAllPublicaciones/${projectId}/${limmit}/${page}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCountPublicacionesAdmin(projectId: number, filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.fecha_min != null && filtros.fecha_min !== "")
				options.params = options.params.set("fecha_min", moment(filtros.fecha_min).format("YYYY-MM-DD") + " 00:00:00");
			if (filtros.fecha_max != null && filtros.fecha_max !== "")
				options.params = options.params.set("fecha_max", moment(filtros.fecha_max).format("YYYY-MM-DD") + " 23:59:59");
			if (filtros.enabled != null && filtros.enabled !== "") options.params = options.params.set("enabled", filtros.enabled);
			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);
			if (filtros.tipo != null && filtros.tipo !== "") options.params = options.params.set("tipo", filtros.tipo);
			if (filtros.autor != null && filtros.autor !== "") options.params = options.params.set("autor", filtros.autor);

			const url = `${environment.urlDomain}/api/v1/admin/getCountPublicaciones/${projectId}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarPublicacion(id: number, body: any) {
		//Modifica ese grupo formativo
		return new Observable<any>((observer) => {
			var update = {};
			if (body.enabled != null) update["enabled"] = body.enabled;
			if (body.visibility != null) update["visibility"] = body.visibility;

			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/admin/modificarPublicacion/" + id;
			this.http.put(url, update, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarPublicacionCompleta(id: number, title: any, author: any, content: any, additional?: any, funespana_especial?: any) {
		//Modifica ese grupo formativo
		return new Observable<any>((observer) => {
			var update = {};

			update["title"] = title;
			update["author_id"] = author;
			update["content"] = content;
			update["funespana_especial"] = funespana_especial;

			update = { ...update, ...additional };

			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/admin/modificarPublicacionCompleta/" + id;
			this.http.put(url, update, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarPublicacionTech(id: number, title: any, author: any, objeto: any, alcance: any, referencia: any, funespana_especial) {
		//Modifica ese grupo formativo
		return new Observable<any>((observer) => {
			var update = {};

			update["title"] = title;
			update["author_id"] = author;
			update["objeto"] = objeto;
			update["alcance"] = alcance;
			update["referencia"] = referencia;
			update["funespana_especial"] = funespana_especial;

			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/admin/modificarPublicacionTech/" + id;
			this.http.put(url, update, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPostInfo(postId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getPostContent/${postId}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPostTypes(projectId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getPostTypes/${projectId}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getVideoOnboarding(id_video: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getDocumentVideo/${id_video}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	// -------- PORTAL DEL EMPLEADO --------

	getAllFestivos(projectId: number, limmit: number, page: number, filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.fecha_min != null && filtros.fecha_min !== "")
				options.params = options.params.set("fecha_min", moment(filtros.fecha_min).format("YYYY-MM-DD") + " 00:00:00");
			if (filtros.fecha_max != null && filtros.fecha_max !== "")
				options.params = options.params.set("fecha_max", moment(filtros.fecha_max).format("YYYY-MM-DD") + " 23:59:59");
			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);
			if (filtros.tipo != null && filtros.tipo !== "") options.params = options.params.set("tipo", filtros.tipo);
			if (filtros.enabled != null && filtros.enabled !== "") options.params = options.params.set("enabled", filtros.enabled);

			const url = `${environment.urlDomain}/api/v1/admin/getAllFestivos/${projectId}/${limmit}/${page}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCountFestivos(projectId: number, filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.fecha_min != null && filtros.fecha_min !== "")
				options.params = options.params.set("fecha_min", moment(filtros.fecha_min).format("YYYY-MM-DD") + " 00:00:00");
			if (filtros.fecha_max != null && filtros.fecha_max !== "")
				options.params = options.params.set("fecha_max", moment(filtros.fecha_max).format("YYYY-MM-DD") + " 23:59:59");
			if (filtros.enabled != null && filtros.enabled !== "") options.params = options.params.set("enabled", filtros.enabled);
			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);
			if (filtros.tipo != null && filtros.tipo !== "") options.params = options.params.set("tipo", filtros.tipo);

			const url = `${environment.urlDomain}/api/v1/admin/getCountFestivos/${projectId}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	addFestivo(body: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			body = {
				...body,
				fecha: moment(body.fecha).format("YYYY-MM-DD"),
				id_proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const url = environment.urlDomain + "/api/v1/admin/newFestivo";

			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarFestivo(id: number, body: any, modify_agrupaciones) {
		return new Observable<any>((observer) => {
			body = {
				...body,
				fecha: moment(body.fecha).format("YYYY-MM-DD"),
			};

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			if (modify_agrupaciones) body["modify_agrupaciones"] = modify_agrupaciones;

			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/modifyFestivo/" + id;

			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteFestivo(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/deleteFestivo/${id}`;

			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getLocalidadesFromProjecto(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = `${environment.urlDomain}/api/v1/admin/getLocalidadesFromProjecto/${this.projectSvc.getProyectoAdmin()}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deteteFoto(personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = `${environment.urlDomain}/api/v1/admin/deleteImagen/${personId}`;

			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDelegacionesFromProjecto(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getDelegacionesFromProjecto/${this.projectSvc.getProyectoAdmin()}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDocumentacion(filtros: any, numPage: number, pageSize: number, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/documentacion?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createDocumentacion(body: any, file: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			body = {
				...body,
				project_id: this.projectSvc.getProyectoAdmin(),
			};

			const formData = new FormData();

			if (file != "") formData.append("file", file, file.name);

			for (const key in body) {
				if (body[key] != null) formData.append(key, body[key]);
			}

			const url = `${environment.urlDomain}/api/v1/admin/documentacion`;

			this.http.post(url, formData, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateDocumentacion(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			console.log("Body: ", body);

			const url = `${environment.urlDomain}/api/v1/admin/documentacion/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteDocumentacion(id: number, filePath: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/documentacion/${id}?filePath=${encodeURIComponent(filePath)}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getFaqs(filtros: any, numPage: number, pageSize: number, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/faq?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createFaq(body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			body = {
				...body,
				project_id: this.projectSvc.getProyectoAdmin(),
			};
			const url = `${environment.urlDomain}/api/v1/admin/faq`;

			this.http.post(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateFaq(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			const url = `${environment.urlDomain}/api/v1/admin/faq/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteFaq(id: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/faq/${id}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getProjects(filtros: any, numPage: number, pageSize: number, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				// proyecto: this.projectSvc.getProyectoAdmin(),
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getProjects?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getVerEvaluaciones(period: number, filtros: any, numPage: number, pageSize: number, onlycount: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				limit: pageSize ? pageSize : environment.pageSize,
				onlycount: onlycount,
			};

			const parametros = { ...params, ...filtros };

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getVerEvaluaciones/${period}?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getCesionHistorial(period: number, personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getVerEvaluaciones/${period}/${personId}?`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	putNoApplyEV(evId: number, noAppy: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/putNoApplyEV/${evId}`;

			this.http.put(url, { noApply: noAppy }, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getVerEvaluacion(evId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getVerEvaluacion/${evId}`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getAllEvPartial(evId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getAllEvPartial/${evId}`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	getPersonUsername(person: number | string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getPersonUsername/${person}`;

			this.http.get(url, options).subscribe(
				(response: any) => observer.next(response),
				(err) => AlertError.showError(err)
			);
		});
	}

	exportarEvaluaciones(filtros: any, periodId: number, userId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				task_name: "ver_evaluaciones",
				subtask_name: "ver_evaluaciones",
				periodId,
				userId,
			};
			const parametros = { ...params, ...filtros };

			const url = environment.urlDomain + "/api/v1/solicitudes-cola/new-task?" + this.helpersSvc.serializeData(parametros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws evaluaciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws evaluaciones", err);
					let msgError = err && err.error ? err.error : "Se ha producido un error exportando los datos";
					observer.error(msgError);
				}
			);

			return { unsubscribe() {} };
		});
	}

	getAllPerfiles(limit: number, pagina: number, filtros: any) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.perfil != null && filtros.perfil !== "") options.params = options.params.set("perfil", filtros.perfil);
			if (filtros.persona != null && filtros.persona !== "") options.params = options.params.set("persona", filtros.persona);

			const url = environment.urlDomain + "/api/v1/admin/getAllPerfiles/" + limit + "/" + pagina;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllPersonasPermiso(rol: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/getAllPersonasPermiso/" + rol;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllPersonasPlantilla(rol: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/getAllPersonasPlantilla/" + rol;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllPermisos(perfil: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/getAllPermisos/" + perfil;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	changeEstadoPermiso(estado, id_perfil, id_permiso, type) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/changeEstadoPermiso/";
			this.http.put(url, { estado, id_perfil, id_permiso, type }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	changeNamePerfil(id_perfil, nombre, descripcion) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/changeNamePerfil/";
			this.http.put(url, { id_perfil, nombre, descripcion }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearPerfil(nombre, descripcion) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/crearPerfil/";
			this.http.post(url, { nombre, descripcion }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	asignacionGrupoPersonas(id_perfil: number, lista_personas: any) {
		//Crea un nuevo grupo formativo
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/asignacionPermisoPersonas";
			var body = {
				id_perfil: id_perfil,
				lista_personas: lista_personas,
			};

			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAsignacionGrupoPersonas(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/deletePermiso/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAllPermiso(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/deleteAllPermiso/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAllAsignacionGrupoPersonas(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/deletePermisoAll/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAsignacionPlantillasPersonas(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/deleteAsignacionPlantillasPersonas/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAllAsignacionPlantillasPersonas(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/deleteAllAsignacionPlantillasPersonas/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deletePerfil(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/deletePerfil/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	setRolPredeterminado(id: number) {
		//Elimina ese grupo en concreto
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/setPredeterminado/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	//Password generate admin

	generateRandomPassword(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/generateRandomPassword";
			this.http.put(url, { personId }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	// Control horario plantilals

	getAllPlantillas(limit: number, pagina: number, filtros: any) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);
			if (filtros.persona != null && filtros.persona !== "") options.params = options.params.set("persona", filtros.persona);

			options.params.set("proyecto", this.projectSvc.getProyectoAdmin());

			const url = environment.urlDomain + "/api/v1/admin/plantillas-horarias/" + limit + "/" + pagina;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPlantillaGeneral() {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			options.params.set("proyecto", this.projectSvc.getProyectoAdmin());

			const url = environment.urlDomain + "/api/v1/admin/plantilla-general/";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	updatePlantilla(general: number, body) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/plantilla-update";
			this.http.put(url, { ...{ general }, ...body }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarProyectoColores(body) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/modificarProyectoColores";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postPlantilla(name: string) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/postPlantilla";
			this.http.post(url, { name }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deletePlantilla(id: number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/plantilla-delete/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	asignacionPlantillaPersonas(id_perfil: number, lista_personas: any) {
		//Crea un nuevo grupo formativo
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			const url = environment.urlDomain + "/api/v1/admin/asignacionPlantillaPersonas";
			var body = {
				id_perfil: id_perfil,
				lista_personas: lista_personas,
			};

			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	//POLITICAS
	getPoliticasDepartamento(limit: number, pagina: number, enabled?: number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url =
				environment.urlDomain + `/api/v1/admin/politicas/${limit}/${pagina}?${enabled == 1 ? this.helpersSvc.serializeData({ enabled: 1 }) : ""}`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modifyPoliticasDepartamento(id: number, data) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			let body = { id };
			if (data.name != null) body["name"] = data.name;
			if (data.enabled != null) body["enabled"] = data.enabled;
			if (data.deleted != null) body["deleted"] = data.deleted;

			const url = environment.urlDomain + `/api/v1/admin/politicas/modify`;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	newPoliticasDepartamento(name: string) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + `/api/v1/admin/politicas/new`;
			this.http.post(url, { name }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	//Politicas

	getDocumentacionDepartamentos(departamentoId: number, numPage: number, pageSize: number, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
				departamentoId,
			};

			const parametros = params;

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/documentacionDepartamento?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createDocumentacionDepartamentos(body: any, file: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			body = {
				...body,
				project_id: this.projectSvc.getProyectoAdmin(),
			};

			const formData = new FormData();

			if (file != "") formData.append("file", file, file.name);

			for (const key in body) {
				if (body[key] != null) formData.append(key, body[key]);
			}

			const url = `${environment.urlDomain}/api/v1/admin/documentacionDepartamento`;

			this.http.post(url, formData, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateDocumentacionDepartamentos(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			console.log("Body: ", body);

			const url = `${environment.urlDomain}/api/v1/admin/documentacionDepartamento/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteDocumentacionDepartamentos(id: number, filePath: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/documentacionDepartamento/${id}?filePath=${encodeURIComponent(filePath)}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	getFaqsDepartamentos(departamentoId: number, numPage: number, pageSize: number, orden: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
				departamentoId,
			};

			const parametros = params;

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/faqDepartamento?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createFaqDepartamentos(body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			body = {
				...body,
				project_id: this.projectSvc.getProyectoAdmin(),
			};
			const url = `${environment.urlDomain}/api/v1/admin/faqDepartamento`;

			this.http.post(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateFaqDepartamentos(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			const url = `${environment.urlDomain}/api/v1/admin/faqDepartamento/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteFaqDepartamentos(id: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/faqDepartamento/${id}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	//Liquidaciones

	getLiquidaciones(numPage: number, pageSize: number, filtros) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.agno != null && filtros.agno !== "") options.params = options.params.set("agno", filtros.agno);
			if (filtros.mes != null && filtros.mes !== "") options.params = options.params.set("mes", filtros.mes);
			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);

			const url = environment.urlDomain + `/api/v1/admin/liquidaciones/${numPage}/${pageSize}`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getConceptosLiquidaciones(numPage: number, pageSize: number, person, mes, agno) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			options.params = options.params.set("agno", agno);
			options.params = options.params.set("mes", mes);
			options.params = options.params.set("id", person);

			const url = environment.urlDomain + `/api/v1/admin/conceptosLiquidacion/${pageSize}/${numPage}`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postConcepto(id_persona: number, mes: number, agno: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/liquidaciones/post`;

			this.http.post(url, { id_persona, mes, agno, ...body }, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	putConcepto(id, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/liquidaciones/put/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteConcepto(id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/liquidaciones/delete/${id}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	// Archivos tienda

	getDocumentacionTienda(productoId: number, numPage: number, pageSize: number, orden: any, onlyenabled?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
				productoId,
				onlyenabled,
			};

			const parametros = params;

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getDocumentacionTienda?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createDocumentacionTienda(body: any, file: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			body = {
				...body,
				project_id: this.projectSvc.getProyectoAdmin(),
			};

			const formData = new FormData();

			if (file != "") formData.append("file", file, file.name);

			for (const key in body) {
				if (body[key] != null) formData.append(key, body[key]);
			}

			const url = `${environment.urlDomain}/api/v1/admin/createDocumentacionTienda`;

			this.http.post(url, formData, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateDocumentacionTienda(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			console.log("Body: ", body);

			const url = `${environment.urlDomain}/api/v1/admin/updateDocumentacionTienda/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteDocumentacionTienda(id: number, filePath: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/deleteDocumentacionTienda/${id}?filePath=${encodeURIComponent(filePath)}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	//PORTAL EMPLEO

	getOfertasEmpleo(numPage: number, pageSize: number, onlyenabled: number, filters?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			let params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				proyecto: this.projectSvc.getProyectoAdmin(),
				onlyenabled,
			};

			if (filters?.name) {
				params = { ...params, ...{ name: filters.name } };
			}
			if (filters?.id) {
				params = { ...params, ...{ id: filters.id } };
			}
			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getOfertasEmpleo?${this.helpersSvc.serializeData(params)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	getOfertasEmpleoWeb(numPage: number, pageSize: number, onlyenabled: number, filters?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			let params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				proyecto: this.projectSvc.getProyectoAdmin(),
				onlyenabled,
			};

			if (filters?.name) {
				params = { ...params, ...{ name: filters.name } };
			}
			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getOfertasEmpleoWeb?${this.helpersSvc.serializeData(params)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	getOfertaEmpleoWeb(id: Number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getOfertaEmpleoWeb/${id}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createOfertasEmpleo(body: any, file: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			const formData = new FormData();

			for (const key in body) {
				if (body[key] != null) formData.append(key, body[key]);
			}

			const url = `${environment.urlDomain}/api/v1/admin/createOfertasEmpleo`;

			this.http.post(url, formData, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateOfertasEmpleo(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			console.log("Body: ", body);

			const url = `${environment.urlDomain}/api/v1/admin/updateOfertasEmpleo/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteOfertasEmpleo(id: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/deleteOfertasEmpleo/${id}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	aplicarAOferta(personId: number, ofertaId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/aplicarAOferta`;

			this.http.post(url, { personId, ofertaId }, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	notificarOfertaEmpleo(idOferta: number): Observable<any> {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: `Bearer ${accessToken}`,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};
		const url = `${environment.urlDomain}/api/v1/admin/notificarOfertaEmpleo`;

		return this.http.post(url, { idOferta }, options);
	}

	//PORTAL EMPLEO SOLICITUD
	getOfertasEmpleoConPersona(numPage: number, pageSize: number, filters?: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			let params: any = {
				page: numPage,
				pageSize: pageSize,
				project: this.projectSvc.getProyectoAdmin(),
			};

			// Agrega filtros
			if (filters?.name) {
				params.name = filters.name;
			}
			if (filters?.persona) {
				params.persona = filters.persona;
			}

			// Agrega la cabecera con el token
			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			// Construye la URL para el nuevo endpoint
			const url = `${environment.urlDomain}/api/v1/admin/getOfertasEmpleoConPersona?${this.helpersSvc.serializeData(params)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("Respuesta del servidor:", response);
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	// Archivos portal empleo

	getDocumentacionPortalEmpleo(productoId: number, numPage: number, pageSize: number, orden: any, onlyenabled?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
				orden: JSON.stringify(orden),
				proyecto: this.projectSvc.getProyectoAdmin(),
				productoId,
				onlyenabled,
			};

			const parametros = params;

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getDocumentacionPortalEmpleo?${this.helpersSvc.serializeData(parametros)}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	getDocumentacionPost(postId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/getDocumentacionPost/${postId}`;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => AlertError.showError(err)
			);
		});
	}

	createDocumentacionPortalEmpleo(body: any, file: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			body = {
				...body,
				project_id: this.projectSvc.getProyectoAdmin(),
			};

			const formData = new FormData();

			if (file != "") formData.append("file", file, file.name);

			for (const key in body) {
				if (body[key] != null) formData.append(key, body[key]);
			}

			const url = `${environment.urlDomain}/api/v1/admin/createDocumentacionPortalEmpleo`;

			this.http.post(url, formData, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	updateDocumentacionPortalEmpleo(id: number, body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
				}),
			};

			delete body.file;

			for (const key in body) {
				if (body[key] == null) delete body[key];
			}

			console.log("Body: ", body);

			const url = `${environment.urlDomain}/api/v1/admin/updateDocumentacionPortalEmpleo/${id}`;

			this.http.put(url, body, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	deleteDocumentacionPortalEmpleo(id: number, filePath: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: `Bearer ${accessToken}`,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = `${environment.urlDomain}/api/v1/admin/deleteDocumentacionPortalEmpleo/${id}?filePath=${encodeURIComponent(filePath)}`;

			this.http.delete(url, options).subscribe(
				(res: any) => observer.next(res),
				(err) => AlertError.showError(err)
			);
		});
	}

	// Vacaciones Calendarios

	getAllAlmanaques(limit: number, pagina: number, filtros: any) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.nombre != null && filtros.nombre !== "") options.params = options.params.set("nombre", filtros.nombre);

			const url = environment.urlDomain + "/api/v1/admin/getAllAlmanaques/" + limit + "/" + pagina;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllPersonasAlmanaque(almanaque: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/getAllPersonasAlmanaque/" + almanaque;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modifyAlmanaque(body: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/modifyAlmanaque";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAllAlmanaque(almanaque: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/deleteAllAlmanaque/" + almanaque;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	asignacionAlmanaquePersonas(id_calendario: number, lista_personas: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			var body = {
				id_calendario: id_calendario,
				lista_personas: lista_personas,
			};

			const url = environment.urlDomain + "/api/v1/admin/asignacionAlmanaquePersonas";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearAlmanaque(body: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/crearAlmanaque";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	deleteAsoAlmanaque(asociacion: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/deleteAsoAlmanaque/" + asociacion;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteAlmanaque(almanaque: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/deleteAlmanaque/" + almanaque;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllAlmanaqueRestriccion(calendarId: number, limit: number, pagina: number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/getAllAlmanaqueRestriccion/" + calendarId + "/" + limit + "/" + pagina;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modifyRestriccionesAlmanaque(body: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/modifyRestriccionesAlmanaque";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	crearRestriccionAlmanaque(body: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/crearRestriccionAlmanaque";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteRestriccionAlmanaque(restriccion: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/deleteRestriccionAlmanaque/" + restriccion;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllDiasCalendario(calendarId: number, limit: number, pagina: number, filtros: any) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.agno != null && filtros.agno !== "") options.params = options.params.set("agno", filtros.agno);
			if (filtros.puestos != null && filtros.puestos !== "") options.params = options.params.set("puestos", filtros.puestos);
			if (filtros.departamentos != null && filtros.departamentos !== "") options.params = options.params.set("departamentos", filtros.departamentos);
			if (filtros.delegaciones != null && filtros.delegaciones !== "") options.params = options.params.set("delegaciones", filtros.delegaciones);
			if (filtros.generos != null && filtros.generos !== "") options.params = options.params.set("generos", filtros.generos);
			if (filtros.evaluado != null && filtros.evaluado !== "") options.params = options.params.set("evaluado", filtros.evaluado);
			if (filtros.evaluador != null && filtros.evaluador !== "") options.params = options.params.set("evaluador", filtros.evaluador);

			const url = environment.urlDomain + "/api/v1/admin/getAllDiasCalendario/" + calendarId + "/" + limit + "/" + pagina;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllDiasCalendarioYears(calendarId: number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/getAllDiasCalendarioYears/" + calendarId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteDiasVacaciones(diasId: number) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/deleteDiasVacaciones/" + diasId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createDiasVacacionesPro(body: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/createDiasVacacionesPro";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	modificarDiasVacacionesPro(body: any) {
		//Devuelve todos los grupos formativos del proyecto que cumplan con los filtros que deseamos, los filtros le serán pasados como parámetro http
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};

			const url = environment.urlDomain + "/api/v1/admin/modificarDiasVacacionesPro";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	putObjetivoVigencia(id, vigencia): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/putObjetivoVigencia/" + id;
			this.http.put(url, { vigencia }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
}
